<template>
  <div class="hader">
    <div class="menuIcon-div">
      <!-- <menuIcon :drawer="drawer" @click="handleDrawer"></menuIcon> -->
      <div @click="$router.go(-1)">
        <span style="font-size: 18px; color: rgb(183, 28, 33)"> 返回</span>
      </div>
    </div>
    <div class="logo-wrap">
      <img class="logo" @click="link()" src="../assets/logo.png" />
      <div
        class="phone-email"
        v-if="$store.state.about.phone && $store.state.about.email"
      ></div>
      <span
        class="span-img"
        style="margin-right: 65px; float: right; margin-top: 2px"
      >
        <a href="tel:010-67131688" class="email-text">
          <img style="width: 20px" src="../assets/telephone_img.png" alt />
        </a>
      </span>
    </div>

    <!-- 手机端导航 -->
    <el-drawer
      :visible.sync="drawer"
      :append-to-body="true"
      :modal="false"
      :show-close="false"
      size="300px"
      direction="rtl"
    >
      <div style="text-align: center">
        <img
          class="logo"
          @click="link()"
          style="width: 90%"
          src="../assets/logo.png"
        />
        <ul class="menu-list">
          <li
            v-for="(item, index) in this.$store.state.menuData"
            v-if="index > 9"
            :key="index"
            :class="'nab_' + index"
            @click="goAnchor('#page_' + index, index)"
          >
            {{ item.menuName }}
          </li>
        </ul>
      </div>
    </el-drawer>

    <!-- 回到顶部 -->
    <button
      @click="backTop()"
      style="position: fixed; right: 20px; bottom: 126px"
    >
      <img class="bank_img" src="../assets/bank_img.png" alt />
    </button>
    <!-- 联系 -->
    <div class="lianxi">
      <span class="span_email" style="background: #f19471; color: #fff">
        <span class="span-img">
          <img style="margin-top: 9px" src="../assets/home/nav/wx.png" alt />
        </span>
        <a
          target="_blank"
          href="http://p.qiao.baidu.com/cps/chat?siteId=15869372&userId=31350453&siteToken=730f6e8d0942a32570f76346adb1f82b&cp=&cr=www.jinglaw.cn&cw="
          class="email-text"
        >
          在线咨询</a
        >
      </span>
      <span class="span_phone" style="background: #b71c21">
        <span class="span-img">
          <img src="../assets/home/nav/tel.png" alt />
        </span>

        <a href="tel:010-67131688" class="email-text"> 电话咨询</a>
      </span>
    </div>
  </div>
</template>

<script>
import menuIcon from "./menu-icon";
export default {
  name: "navBar",
  components: {
    menuIcon,
  },
  props: {
    menuList: {
      type: Array,
      default: [],
    },
    typePage: {
      type: Object,
    },
  },
  data() {
    return {
      drawer: false,
    };
  },
  created() {},
  methods: {
    handleDrawer() {
      this.drawer = !this.drawer;
    },
    // 描点事件
    goAnchor(selector, index) {
      var anchor = document.querySelector(selector);

      if (this.typePage) {
        if (this.typePage.type) {
          sessionStorage.setItem(
            "scrollpage",
            this.$store.state.domHight[index - 9].height
          );
          if (index == 9) {
            this.$router.push("/recruit");
          } else {
            this.$router.push({ path: "/" });
          }
        }
      } else {
        // document.documentElement.scrollTop = anchor.offsetTop;
        if (index == 9) {
          this.$toTop();
          this.$router.push("/recruit");
        } else {
          this.scrollTop(anchor.offsetTop, 500);
        }
      }
    },

    // 详情页面跳转
    link(dom) {
      this.$router.push({ path: "/" });
    },

    scrollTop(number, time) {
      if (!time) {
        document.body.scrollTop = document.documentElement.scrollTop = number;
        return number;
      }
      const spacingTime = 20; // 设置循环的间隔时间  值越小消耗性能越高
      let spacingInex = time / spacingTime; // 计算循环的次数
      let nowTop = document.body.scrollTop + document.documentElement.scrollTop; // 获取当前滚动条位置
      let everTop = (number - nowTop) / spacingInex; // 计算每次滑动的距离
      let scrollTimer = setInterval(() => {
        if (spacingInex > 0) {
          spacingInex--;
          this.scrollTop((nowTop += everTop));
        } else {
          clearInterval(scrollTimer); // 清除计时器
        }
      }, spacingTime);
    },

    backTop() {
      // let body = document.querySelector('body')

      this.scrollTop(0, 500);
    },
  },
};
</script>

<style lang="scss" scoped>
.hader {
  // background-image: linear-gradient(to right, #92797c 0%, #fefefe 100%);
  button {
    background: none;
    border: none;
    outline: none;
    cursor: pointer;
  }
  .menu-div {
    background-color: #b71c21;
  }
}
.logo-wrap {
  width: 1200px;
  margin: 0 auto;
  padding: 10px;

  .logo {
    width: 350px;
    cursor: pointer;
  }
  .phone-email {
    float: right;
    display: flex;
    justify-content: space-between;
    width: 360px;
    height: 54px;
    // margin-top: 12px;
    .div-concent-img {
      width: 20px;
      // height: 44px;
    }
    a,
    span {
      font-size: 14px;
      font-family: Source Han Sans CN;
      font-weight: 400;
      color: #666666;
      // text-decoration: none;
      margin-left: 8px;
    }
    .div-concent1 {
      display: flex;
      justify-content: space-around;
      flex-flow: column;
      width: 300px;
      > div {
        width: 100%;
      }
    }
    .div-concent {
      height: 56px;
      margin-top: 6px;
      width: 60px;
      transform: translateX(0);
      /* 移回也需要0.4s时间过渡 */
      transition: transform 0.4s;

      img {
        height: 44px;
      }
    }

    .zx {
      font-size: 16px;
      font-family: Source Han Sans CN;
      font-weight: 400;
      color: #4d4d4d;
    }
  }

  .phone-email:hover {
    .div-concent {
      transform: translateX(15px);
      /* 0.4s完成transform移动效果*/
      transition: transform 0.4s;
    }
  }
}
.menuIcon-div {
  display: none;
}
.bank_img {
  width: 48px;
}
.menu-wrap {
  width: 1200px;
  margin: 0 auto;
  display: flex;
  justify-content: left;
  line-height: 60px;

  li {
    padding: 0 30px;
    text-align: center;
    color: #fff;
    &:hover {
      background-color: #470809;
      cursor: pointer;
      opacity: 1;
      transition: all 0.4s ease-in-out;
    }
  }
}

button {
  position: fixed;
  right: 20px;
  bottom: 20px;
  z-index: 10;
}
.nab_2,
.nab_8 {
  display: none;
}
.lianxi {
  display: none;
}
@media (max-width: 1000px) {
  .hader {
    position: relative;
    // background-image: linear-gradient(to right, #92797c 0%, #fefefe 100%);
    .menuIcon-div {
      display: block;
      position: fixed;
      top: 10px;
      right: 19px;
      z-index: 10;
    }
    button {
      background: none;
      border: none;
      outline: none;
      cursor: pointer;
    }
    .menu-div {
      background-color: #b71c21;
      display: none;
    }
  }
  .logo-wrap {
    width: 100%;
    margin: 0 auto;
    padding: 10px 0;

    .logo {
      width: 50%;
      margin-left: 20px;
      cursor: pointer;
    }
    .phone-email {
      float: right;
      display: flex;
      display: block;
      justify-content: space-between;
      flex-flow: row wrap;
      width: 30%;
      height: 54px;
      // margin-top: 12px;
      .div-concent-img {
        width: 20px;
        // height: 44px;
      }
      a,
      span {
        font-size: 14px;
        font-family: Source Han Sans CN;
        font-weight: 400;
        color: #666666;
        // text-decoration: none;
        margin-left: 8px;
      }
      .div-concent1 {
        display: flex;
        justify-content: space-around;
        flex-flow: column;
        width: 20%;
        > div {
          width: 100%;
        }
      }
      .div-concent {
        height: 56px;
        margin-top: 6px;
        width: 60px;
        transform: translateX(0);
        /* 移回也需要0.4s时间过渡 */
        transition: transform 0.4s;

        img {
          height: 44px;
        }
      }

      .zx {
        font-size: 16px;
        font-family: Source Han Sans CN;
        font-weight: 400;
        color: #4d4d4d;
      }
    }

    .phone-email:hover {
      .div-concent {
        transform: translateX(15px);
        /* 0.4s完成transform移动效果*/
        transition: transform 0.4s;
      }
    }
  }
  .bank_img {
    width: 32px;
    border-radius: 50%;
  }
  .menu-wrap {
    width: 100%;
    margin: 0 auto;
    display: flex;
    justify-content: left;
    flex-flow: row wrap;
    line-height: 60px;

    li {
      padding: 0 30px;
      text-align: center;
      color: #fff;
      &:hover {
        background-color: #470809;
        cursor: pointer;
        opacity: 1;
        transition: all 0.4s ease-in-out;
      }
    }
  }

  button {
    position: fixed;
    right: 20px;
    bottom: 20px;
    z-index: 10;
  }
  .nab_2,
  .nab_8 {
    display: none;
  }
  .menu-list {
    li {
      // text-align: left;
      color: #666666;
      font-size: 12px;
      padding: 8px 0;
      border-bottom: 1px solid #dfdcdc;
    }
  }
  .lianxi {
    position: fixed;
    bottom: 0;
    left: 0;
    z-index: 11;
    display: none;
    // height: 60px;
    padding: 0px 0;
    background: #fff;
    width: 100%;
    // background: url("../assets/web/bj2.png") no-repeat;
    display: flex;
    justify-content: space-around;
    > span {
      display: inline-block;
      width: 50%;
      height: 60px;
      line-height: 60px;
      text-align: center;
      .span-img {
        line-height: 30px;
      }
    }
    .span-img {
      margin-right: 4px;
      border-radius: 50%;
      display: inline-block;
      height: 30px;
      width: 30px;
      vertical-align: -4px;
      // background: #fff;
      text-align: center;
      img {
        width: 20px;
        margin-top: 4px;
      }
    }
    a {
      color: #fff;
    }
  }
}
.email-text {
  text-decoration: none;
}
</style>
