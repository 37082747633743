<template>
  <div class="page3">
 <navBar :menuList="navList" :typePage="typePage"></navBar>
    <swiper isHide="true"></swiper>
    <div class="content">
     
      <ul class="content-ul">
        <li v-for="(item, index) in articleList" :key="index">
          <div
            style="display: flex; justify-content: space-between"
            @click="details(item)"
          >
            <div style="width: 52%;height:120px">
              <img class="img2" :src="item.imgPath" alt="" />
            </div>
            <div style="width: 40%">
              <div
                style="
                  font-size: 18px;
                  margin-top: 5px;
                  letter-spacing: 0;
                  overflow: hidden;
                  display: -webkit-box;
                  text-overflow: ellipsis;
                  -webkit-line-clamp: 1;
                  -webkit-box-orient: vertical;
                "
              >
                {{ item.title }}
              </div>
              <div
                style="
                  font-size: 14px;
                  margin-top: 5px;
                  letter-spacing: 0;
                  overflow: hidden;
                  display: -webkit-box;
                  text-overflow: ellipsis;
                  -webkit-line-clamp: 3;
                  -webkit-box-orient: vertical;
                "
              >
                {{ item.summary }}
              </div>
            </div>
          </div>
        </li>
      </ul>
    </div>
    <!-- 弹框 -->
    <div>
      <el-dialog
        class="div-dialog"
        title
        :visible.sync="centerDialogVisible"
        margin-top="10vh"
        width="60%"
        center
      >
        <div>
          <div class="article-title">{{ menuDatas.title }}</div>
          <div v-if="!menuDatas.content">没有内容！</div>
          <p
            v-html="menuDatas.content"
            v-else
            class="content-wrap content-text;width:100%"
          ></p>
        </div>
      </el-dialog>
    </div>
  </div>
</template>

<script>
import { Swiper, SwiperSlide } from "vue-awesome-swiper";
import { requestMenu, requestArticleDetail, requestArticleList } from "@/api";
import swiper from "./swiper.vue";
import navBar from "@/components/navBar1.vue";
import btn from "@/components/btn";

export default {
  name: "page3",
  props: {
    menuData: {
      type: Object,
      default: () => {},
    },
  },
  components: {
    navBar,
    swiper,
    btn,
  },
  data() {
    return {
      menuDatas: {
        dialogLoading: false,
        content: "",
        title: "",
        imgPath: "",
      },
      centerDialogVisible: false,
      titleList: [
        { name1: "32项", name2: "专业维权步骤" },
        { name1: "10余年", name2: "拆迁经验" },
        { name1: "1000+", name2: "团队成功案件" },
      ],
      menuList: [],
      articleList: [],
      page: {
        page: 1,
        total: 0,
        pageSize: 4,
      },
    };
  },
  computed: {},
  created() {
    this.getMenu();
  },

  methods: {
    async getMenu() {
      let { data } = await requestMenu({ menuId: this.$route.query.menuId });

      console.log(data);
      this.menuList = data;
      if (this.menuList.length > 0) {
        this.requestArticleList(this.menuList[0].menuId);
        // this.activeIndex = this.menuList[0].menuId;
      }
      // this.intnData();
    },

    // 文章
    requestArticleList(id, index) {
      let that = this;
      requestArticleList({
        menuId: id,
        ...this.page,
      }).then((res) => {
        that.articleList = res.data;
        console.log(that.articleList);
        that.page = res.page;
      });

      //   console.log(data);
    },

    //文章详情
    // 弹框
    details(item) {
      let that = this;
      requestArticleDetail({ id: item.id }).then((res) => {
        that.menuDatas = res.data;
        that.menuDatas.dialogLoading = false;
        that.menuDatas.content = res.data.content;
        that.menuDatas.title = res.data.title;
        that.menuDatas.imgPath = res.data.imgPath;
        that.centerDialogVisible = true;
      });
    },

    async getMenuList() {
      this.loading = true;
      let { data } = await requestMenu();
      this.menuList = data;
      this.loading = false;
    },

    //文章详情
    particulars(id) {
      debugger;
      this.$router.push({
        path: "/page3Particulars",
        query: { id: id },
      });
    },
  },
};
</script>
<style lang="scss" scoped>
@media (max-width: 1000px) {
  .page3 {
    width: 100%;
    margin: 0 auto;
    position: relative;
    margin-top: 10px;
    .content {
      ul {
        padding: 20px;
      }
    }
  }
  .content-ul {
    // padding-bottom: 10px;
    li {
      background: #fff;
      padding: 20px 10px;
      div {
        // color: #fff;
        position: relative;
        video {
          width: 100%;
          height: 100%;
          // border: 1px solid gray;
          // background: red;
          padding-top: 10px;
        }
        .img1 {
          position: absolute;
          top: -5px;
          right: 20px;
          width: 24px;
        }
        .img2 {
          width: 100%;
          height: 100%;
        }
      }
    }
  }
  .btn {
    width: 60%;
    height: 40px;
    line-height: 38px;
    margin: 0 auto;
    margin-top: 24px;
  }
}
</style>



