<template>
  <div class="page2">
    <navBar></navBar>
    <swiper isHide="true"></swiper>
    <div class="header_img">
      <!-- <img
        style="width: 100%; margin: 10px 0"
        src="../assets/home/page2/bj.png"
        alt=""
      /> -->
    </div>
    <div v-loading="menuData.dialogLoading">
      <div class="article-title" >{{ menuData.title }}</div>
      <div v-if="!menuData.content">没有内容！</div>
      <div class="article_img" v-if="menuData.imgPath">
        <!-- <img
              :src="menuData.imgPath"
              alt
              style="max-width: 100%;padding: 25px 36px;box-sizing: border-box"
            />-->
      </div>
      <div
        v-if="
          menuData.content &&
          typeof content === 'string' &&
          menuData.content.indexOf(`<video`) !== -1
        "
        style="text-align: center"
      ></div>
      <p v-html="menuData.content" v-else class="content-wrap content-text"></p>
    </div>
  </div>
</template>

<script>
import navBar from "@/components/navBar1.vue";
import swiper from "./swiper.vue";
// 详情
import { requestArticleDetail } from "@/api";

export default {
  name: "pageParticulars",

  components: {
    navBar,
    swiper,
  },
  data() {
    return {
      menuList: [],
      articleList: [],
      page: {
        page: 1,
        total: 0,
        pageSize: 4,
      },
      menuData: {
        dialogLoading: false,
        content: "",
        title: "",
        imgPath: "",
      },
    };
  },
  computed: {},
  created() {
    this.initDetails();
  },

  methods: {
    //文章详情

    initDetails(id, index) {
      let that = this;
      that.menuData.dialogLoading = true;
      requestArticleDetail({ id: this.$route.query.id }).then((res) => {
        that.menuData.dialogLoading = false;
        that.menuData.content = res.data.content;
        that.menuData.title = res.data.title;
        that.menuData.imgPath = res.data.imgPath;

        if (!that.centerDialogVisible) {
          that.centerDialogVisible = true;
        }
      });
    },
  },
};
</script>
<style lang="scss" scoped>
@media (max-width: 1000px) {
  .page2 {
    width: 92%;
    margin: 0 auto;
    padding-bottom: 90px;
  }
  .article-title{
    padding: 0 20px;
    font-weight: 600;
    margin-top: 10px;
  }
  p{ 
    // text-indent:2em;
    margin: 0 !important;
    padding: 0 !important;
  }
  video{
    width: 100% !important;
  }
}
</style>



