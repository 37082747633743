<template>
  <!-- 维权指南详情 -->
  <div>
    <navBar :menuList="navList" :typePage="typePage"></navBar>
    <swiper isHide="true"></swiper>
    <div class="page_8">
      <div class="div-ul" style="display: none">
        <ul>
          <li @click="linkshouye()">
            <button>首页</button>
          </li>
          <li
            v-for="(item, index) in menuList"
            :key="index"
            @click="getNum(index, item.menuId)"
          >
            <button :class="{ active: num === index }">
              {{ item.menuName }}
            </button>
          </li>
        </ul>
      </div>

      <div class="tabCon">
        <ul
          v-for="(item, index) in menuList"
          :key="index"
          v-show="index == num"
        >
          <li
            class="tabCon_li"
            v-for="(items, indexs) in articleList"
            :key="indexs"
            @click="particulars(items.id, indexs)"
          >
            <div class="tabCon_list">
              <!-- {{items.imgPath}}{{items.imgPathOther}} -->
              <div class="tabCon_list_img">
                <!-- {{items.imgPath}} -->
                <el-image
                  :src="items.imgPath ? items.imgPath : items.imgPathOther"
                ></el-image>
              </div>
              <div class="tabCon_list_content">
                <div class="tabCon_list_content_title">{{ items.title }}</div>
                <div class="tabCon_list_content_summary">
                  {{ items.summary }}
                </div>
                <div class="tabCon_list_content_time">
                  {{ items.publishTime }}
                </div>
              </div>
            </div>
          </li>
        </ul>
      </div>
      <div
        class="pagination-div"
        style="margin-bottom: 20px; text-align: center"
      >
        <el-pagination
          background
          layout="prev, pager, next"
          :page-size="page.pageSize"
          @current-change="handlePage"
          :total="page.total"
        ></el-pagination>
      </div>
      <!-- <button v-show="this.$route.query.show != 0" @click="link()" class="btn">
        查看更多
      </button> -->
      <footerModule :isBottom="true"></footerModule>
    </div>
    <!-- 弹框 -->
    <div>
      <el-dialog
        title
        :visible.sync="centerDialogVisible"
        margin-top="10vh"
        width="60%"
        center
      >
        <div v-loading="menuData.dialogLoading">
          <div class="article-title">{{ menuData.title }}</div>
          <div v-if="!menuData.content">没有内容！</div>
          <div class="article_img" v-if="menuData.imgPath">
            <!-- <img
              :src="menuData.imgPath"
              alt
              style="max-width: 100%;padding: 25px 36px;box-sizing: border-box"
            />-->
          </div>
          <div
            v-if="
              menuData.content &&
              typeof content === 'string' &&
              menuData.content.indexOf(`<video`) !== -1
            "
            style="text-align: center"
          >
            <video
              class="bg_video"
              :src="getSrc(menuData.content)[1]"
              style="max-height: 70vh; max-width: 100%"
              controls
            ></video>
          </div>
          <p
            v-html="menuData.content"
            v-else
            class="content-wrap content-text"
          ></p>

          <!-- 上一个 或下一个 -->
          <div
            class="swiper-button-prev view-prev"
            slot="button-prev"
            @click="handlePrev"
          ></div>
          <div
            class="swiper-button-next view-next"
            slot="button-next"
            @click="handleNext"
          ></div>
        </div>
      </el-dialog>
    </div>

    <!-- {{menuData}}1111 -->
    <!-- <button @click="linkshouye()">返回</button> -->
  </div>
</template>

<script>
import { requestMenu, requestArticleList, requestArticleDetail } from "@/api";
import swiper from "./swiper.vue";
import navBar from "@/components/navBar1.vue";
import page1 from "@/views/page1.vue";
import footerModule from "@/components/footerModule.vue";
// import pageTion from "@/components/pageTion.vue";
export default {
  name: "pagearticle",

  components: {
    navBar,
    swiper,
    page1,
    footerModule,
    // pageTion,
  },

  props: {
    // menuData: {
    //   type: Object,
    //   default: {},
    // },
    // a: {
    //   type: String,
    // },
  },

  data() {
    return {
      centerDialogVisible: false,
      viewIndex: 0,
      activeIndex: "",
      menuData: {
        dialogLoading: false,
        content: "",
        title: "",
        imgPath: "",
      },
      menuList: [],
      typePage: {
        type: true,
      },
      articleList: [],
      navList: [],
      num: 0,
      page: {
        page: 1,
        total: 0,
        pageSize: 4,
      },
    };
  },

  created() {
    this.getMenu();
    this.getMenuList();
  },
  methods: {
    //   目录
    async getMenu() {
      // console.log(this.$route.query);
      let { data } = await requestMenu({
        menuId: this.$route.query.menuId,
      });
      this.menuList = data;
      if (this.menuList.length > 0) {
        this.requestArticleList(this.menuList[0].menuId);
        this.activeIndex = this.menuList[0].menuId;
      }
    },

    handlePage(page) {
      this.page.page = page;
      this.requestArticleList(this.activeIndex);
    },

    handlePrev() {
      if (this.viewIndex > 0) {
        this.viewIndex -= 1;
        this.viewId = this.articleList[this.viewIndex].id;
        this.initDetails(this.viewId, this.viewIndex);
      }
    },
    handleNext() {
      if (this.viewIndex < this.articleList.length - 1) {
        this.viewIndex += 1;
        this.viewId = this.articleList[this.viewIndex].id;
        this.initDetails(this.viewId, this.viewIndex);
      }
    },

    particulars(id) {
      this.$router.push({
        path: "/pageParticulars",
        query: { id: id, video: true },
      });
    },

    // 文章
    requestArticleList(id, index) {
      // this.viewIndex = index;
      //   let { data } = await requestArticleList({ menuId: id });
      let that = this;
      requestArticleList({
        menuId: id,
        ...this.page,
      }).then((res) => {
        that.articleList = res.data;
        that.page = res.page;
      });

      //   console.log(data);
    },

    async getNum(index, id) {
      this.num = index;
      this.requestArticleList(id);
    },

    async getMenuList() {
      this.loading = true;
      let { data } = await requestMenu();
      this.navList = data;
      this.loading = false;
    },

    initDetails(id, index) {
      let that = this;
      this.activeIndex = id;
      this.viewIndex = index;
      this.menuData.dialogLoading = true;
      requestArticleDetail({ id: id }).then((res) => {
        // that.menudata = res.data;
        that.menuData.dialogLoading = false;
        that.menuData.content = res.data.content;
        that.menuData.title = res.data.title;
        that.menuData.imgPath = res.data.imgPath;
        if (!that.centerDialogVisible) {
          that.centerDialogVisible = true;
        }
      });
    },

    linkshouye(dom) {
      // sessionStorage.setItem("scrollpage", dom);
      this.$router.push({ path: "/" });
    },
  },
};
</script>

<style lang="scss" scoped>
.page_8 {
  margin-top: 60px;
  ul {
    width: 1200px;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    flex-flow: row wrap;
    li {
      width: 164px;
      height: 78px;
      button {
        width: 100%;
        height: 100%;
        border: none;
        cursor: pointer;
        font-size: 16px;
        font-family: Source Han Sans CN;
        font-weight: 400;
        &:hover {
          color: #fff;
          background: #b71c21;
          opacity: 1;
          transition: all 0.4s ease-in-out;
        }
        &:focus {
          outline: none;
        }
      }
    }
    .active {
      color: #fff;
      background: #b71c21;
    }
  }
  .tabCon {
    min-height: 300px;
    margin: 60px 0;
    ul {
      width: 1200px;
      margin: 0 auto;
      margin-top: 40px;
      display: flex;
      justify-content: space-between;
      flex-flow: row wrap;
      .tabCon_li {
        width: 1200px;
        height: 220px;
        background: #f4f4f4;
        margin-top: 20px;
        transition: all 0.4s;
        transform: skewX(0deg) translate(0px, 0px);
        box-shadow: -3px 3px 3px #dad2d2;
        .tabCon_list {
          display: flex;
          justify-content: space-between;
          .tabCon_list_img {
            width: 350px;
            height: 220px;
            transition: all 0.5s;
            // background: red;
            img {
              width: 100%;
              height: 100%;
            }
          }
          .tabCon_list_content {
            width: 830px;
            .tabCon_list_content_title {
              font-size: 22px;
              font-family: Source Han Sans CN;
              font-weight: 400;
              color: #222222;
              margin: 20px 12px 10px 0px;
            }
            .tabCon_list_content_summary {
              font-size: 16px;
              color: #666;
              line-height: 30px;
              margin-bottom: 10px;
              margin-right: 16px;
              text-align: justify;
              overflow: hidden;
              text-overflow: ellipsis;
              display: -webkit-box;
              -webkit-line-clamp: 3;
              -webkit-box-orient: vertical;
            }
            .tabCon_list_content_time {
              font-size: 16px;
              font-size: 16px;
              color: #666;
              line-height: 30px;
              margin-bottom: 10px;
              color: #999;
            }
          }
        }
        &:hover {
          cursor: pointer;
          transition: all 0.4s ease-in-out;
          transform: skewX(0deg) translate(10px, 0px);
          //   background: #b71c21;
          .tabCon_list_content_title {
            color: #b71c21 !important;
            opacity: 1;
            transition: all 0.4s ease-in-out;
          }
        }
      }
    }
  }
  ul {
    padding: 0;
  }
  .btn {
    // width: 269px;
    // height: 60px;
    background: #fff;
    // box-shadow: 0px 1px 16px 0px rgba(183, 28, 33, 0.6);
    // border-radius: 7px;
    border: none;
    margin: 0 auto;
    margin-top: 68px;
    cursor: pointer;
    font-family: Source Han Sans CN;
    font-weight: 400;
    // color: #fff;
    font-size: 16px;
    font-family: Source Han Sans CN;
    font-weight: 400;
    display: block;
    &:hover {
      color: goldenrod;
      opacity: 1;
      transition: all 0.4s ease-in-out;
    }
  }
  .btn:focus {
    outline: none;
  }
}
.article-title {
  text-align: center;
  font-size: 24px;
  margin-bottom: 30px;
  color: #3f3f3f;
  padding: 0 20px;
  font-weight: bold;
}
.article_img {
  text-align: center;
}

.swiper-button-prev {
  // left: 2%;
  width: 60px;
  height: 60px;
  background: url(../assets/prev.png) no-repeat;
  background-size: 100% 100%;
  &:after {
    font-size: 0px;
  }
  position: fixed;
  top: 50%;
}
.swiper-button-next {
  width: 60px;
  height: 60px;
  background: url(../assets/next.png) no-repeat;
  background-size: 100% 100%;
  &:after {
    font-size: 0px;
  }
  position: fixed;
  top: 50%;
  right: 2%;
}
@media (max-width: 1000px) {
  .page_8 {
    margin-top: 30px;
    width: 100%;
    .pagination-div {
      // display: none;
    }
    // overflow-x: auto;
    .div-ul {
      width: 100%;
      overflow-x: auto;
      // height: 48px;
      ul {
        // height: 48px;
        width: 100%;
        margin: 0 auto;
        display: flex;
        justify-content: space-between;
        // flex-flow: row nowrap;
        // white-space: nowrap;
        // overflow-x: scroll;
        li {
          width: 25%;
          height: 32px;
          margin-top: 6px;
          button {
            margin: 2px 6px;
            box-sizing: border-box;
            border-radius: 10px;
            width: 90%;
            height: 100%;
            border: none;
            cursor: pointer;
            font-size: 12px;
            font-family: Source Han Sans CN;
            font-weight: 400;
            // padding: 0 12px;
            &:hover {
              color: #fff;
              background: #b71c21;
              opacity: 1;
              transition: all 0.4s ease-in-out;
            }
            &:focus {
              outline: none;
            }
          }
        }
        .active {
          color: #fff;
          background: #b71c21;
        }
      }
    }
    .tabCon {
      min-height: 300px;
      margin: 10px 0;
      > ul {
        width: 100%;
        margin: 0 auto;
        margin-top: 0px;
        display: flex;
        justify-content: space-between;
        flex-flow: row wrap;
        .tabCon_li {
          width: 100%;
          height: 106px;
          background: #f4f4f4;
          margin-top: 20px;
          transition: all 0.4s;
          transform: none;
          box-shadow: none;
          .tabCon_list {
            display: flex;
            justify-content: space-between;
            .tabCon_list_img {
              width: 40%;
              height: 106px;
              transition: all 0.5s;
              // background: red;
              img {
                width: 100%;
                height: 100%;
              }
            }
            .tabCon_list_content {
              width: 60%;
              .tabCon_list_content_title {
                font-size: 14px;
                font-family: Source Han Sans CN;
                font-weight: 400;
                color: #222222;
                margin: 10px 10px 10px 10px;
                white-space: nowrap;

                overflow: hidden;

                text-overflow: ellipsis;
              }
              .tabCon_list_content_summary {
                font-size: 12px;
                color: #666;
                line-height: 16px;
                margin-bottom: 10px;
                margin-right: 10px;
                margin-left: 10px;
                text-align: justify;
                overflow: hidden;
                text-overflow: ellipsis;
                display: -webkit-box;
                -webkit-line-clamp: 3;
                -webkit-box-orient: vertical;
                overflow: hidden;

                text-overflow: ellipsis;

                display: -webkit-box;

                -webkit-box-orient: vertical;

                -webkit-line-clamp: 2;
              }
              .tabCon_list_content_time {
                font-size: 10px;
                color: #666;
                line-height: 16px;
                margin-left: 10px;
                margin-bottom: 0px;
                color: #999;
              }
            }
          }
          &:hover {
            cursor: pointer;
            transition: none;
            transform: none;
            //   background: #b71c21;
            .tabCon_list_content_title {
              color: #b71c21 !important;
              opacity: 1;
              transition: none;
            }
          }
        }
      }
    }
    ul {
      padding: 0;
    }
    .btn {
      // width: 269px;
      // height: 60px;
      background: #fff;
      // box-shadow: 0px 1px 16px 0px rgba(183, 28, 33, 0.6);
      // border-radius: 7px;
      border: none;
      margin: 0 auto;
      margin-top: 68px;
      cursor: pointer;
      font-family: Source Han Sans CN;
      font-weight: 400;
      // color: #fff;
      font-size: 16px;
      font-family: Source Han Sans CN;
      font-weight: 400;
      display: block;
      &:hover {
        color: goldenrod;
        opacity: 1;
        transition: all 0.4s ease-in-out;
      }
    }
    .btn:focus {
      outline: none;
    }
  }
  .article-title {
    text-align: center;
    font-size: 24px;
    margin-bottom: 30px;
    color: #3f3f3f;
    padding: 0 20px;
    font-weight: bold;
  }
  .article_img {
    text-align: center;
  }

  .swiper-button-prev {
    // left: 2%;
    width: 60px;
    height: 60px;
    background: url(../assets/prev.png) no-repeat;
    background-size: 100% 100%;
    &:after {
      font-size: 0px;
    }
    position: fixed;
    top: 50%;
  }
  .swiper-button-next {
    width: 60px;
    height: 60px;
    background: url(../assets/next.png) no-repeat;
    background-size: 100% 100%;
    &:after {
      font-size: 0px;
    }
    position: fixed;
    top: 50%;
    right: 2%;
  }
}
</style>
